html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-weight: 400;
  font-family: "Noto Sans" !important;
  color: var(--text-color);
  background-color: var(--surface-ground);
}

div,
table,
th,
td,
li,
a,
input,
button {
  font-family: "Noto Sans" !important;
  color: var(--text-color);
}

span :not(.pi) {
  font-family: "Noto Sans" !important;
}

.p-button-label {
  font-weight: 400;
}

.sf-dropdown span {
  padding: 0.25rem;
}

.sf-dropdown .p-dropdown-clear-icon {
  padding: 0rem !important;
}

.sf-dropdown ul,
li {
  padding: 0.25rem !important;
  font-family: "Noto Sans" !important;
  font-size: 0.875rem;
}

.sf-dialog .p-dialog-content {
  padding: 1.25rem 1.25rem 0.25rem 1.25rem !important;
}

.sf-dialog-nohead .p-dialog-content {
  padding: 0.5rem !important;
}

.sf-underline:hover {
  text-decoration: underline;
}

.sf-menu li {
  padding: 0 !important;
}

.sf-menutab {
  font-family: "Noto Sans";
  font-size: 0.9rem;
  height: 3rem;
  line-height: 3rem;
}

.sf-menutab a {
  padding: 0.25rem !important;
  margin-right: 1rem !important;
}

.sf-chip {
  margin: 0.1rem;
}

.sf-chip span {
  margin: 0.1rem !important;
  height: 1.3rem !important;
}

.sf-chip img {
  width:auto;
  height: 1rem !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
  margin: 0 !important;
}

.sf-chip-nofood {
  border-color: var(--text-color);
  height: 1.5rem !important;
}

.p-sidebar-header {
  margin: 0.5rem !important;
  padding: 0.5rem !important;
}

.sf-rating {
  gap: 0.3rem !important;
}

.sf-paginator .p-paginator-element {
  border-radius: 50% !important;
}

.sf-shelfive-logo {
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
}

.sf-shelfive-logo:hover {
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.2)) brightness(110%);
}

.sf-top-searchbar {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.sf-brand-filter .p-dropdown-filter {
  width: 100% !important;
}